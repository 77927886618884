import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import fetch from "cross-fetch";

export const client = new ApolloClient({
	link: new HttpLink({
		uri: process.env.GATSBY_API_URL || "http://127.0.0.1:3000/v1alpha1/graphql",
		fetch
	}),
	cache: new InMemoryCache()
});

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { client } from "./client";

interface IApolloClient {
	element: React.ComponentType;
}
export const wrapRootElement = ({ element }: IApolloClient) => (
	<ApolloProvider client={client}>{element}</ApolloProvider>
);
